import styles from '../styles.module.scss'

type BannerMessageProps = {
  content: string
  icon?: React.ReactNode
}

export const BannerMessage = ({ content, icon }: BannerMessageProps) => {
  return (
    <div className={styles.messageWrapper}>
      {icon}
      <p className={styles.title}>{content}</p>
    </div>
  )
}
