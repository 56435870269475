import { useLocalization } from 'locales/i18n'
import { useMasterLoadable } from 'ui/@store/master'

import { Trader } from '../traders'

import { Banner } from './@components/banner'

export const BannerTraderMarket = () => {
  const { t } = useLocalization('master.overviewTab.sidebar.banner')
  const { master } = useMasterLoadable()

  if (master?.market.isActive) {
    return <Trader />
  }

  return <Banner title={t('tradingPaused.title')} description={t('tradingPaused.description')} />
}
